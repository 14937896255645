import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SupabaseService } from '../services/supabase.service';
import * as Sentry from '@sentry/browser';

export const profileGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const service = inject(SupabaseService);

  return new Observable<boolean>(observer => {
    service.session
      .then(session => {
        if (session) {
          observer.next(true);
          observer.complete();
        } else {
          router.navigate(['/welcome']);
          observer.next(false);
          observer.complete();
        }
      })
      .catch(error => {
        router.navigate(['/welcome']);
        observer.next(false);
        observer.complete();
        Sentry.captureException(error);
      });
  });
};
